import { createTheme } from "@material-ui/core"

// Docs for the default values are found here: https://material-ui.com/customization/default-theme/
// Below values are Telstra values that override the defualt values

const telstraTheme = createTheme({
  typography: {
    fontFamily: ["Akkurat", "Helvetica", "Arial", "sans-serif"].join(","),
    // fontFamily: ['Open Sans', "sans-serif"].join(","),
    // fontFamily: ['Nunito', "sans-serif"].join(","),
    h1: {
      fontSize: "2.5rem",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.75rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.25rem",
    },
    h6: {
      fontSize: "1rem",
    },
  },
  palette: {
    // Magenta
    primary: {
      light: "#ffbedf",
      main: "#a0148c",
      dark: "#570066",
    },
    // Purple
    secondary: {
      light: "#dcdeee",
      main: "#5e50bf",
      dark: "#35237f",
    },
  },
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: "1.2rem",
        fontWeight: "bolder !important",
        color: "#a0148c",
      },
    },
    MuiTablePagination: { root: { display: "grid", justifyContent: "center" } },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.875rem",
        color: "#fff",
        backgroundColor: "#555",
        borderRadius: "5px",
      },
    },
    MuiTableCell: {
      root: {
        // height: "10px !important",
        padding: "6px !important",
        fontSize: "0.8rem!important",
      },
    },
    MuiIconButton: {
      root: {
        padding: "0 ",
        fontSize: "inherit",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "0.4em",
        },
        "*::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
          // backgroundColor: 'red',
          backgroundColor: "rgba(0,0,0,.1)",
          outline: "1px solid slategrey",
        },
      },
    },
    MuiCheckbox: {
      root: {
        padding: "0",
      },
    },
  },
})

export default telstraTheme
