import { createSlice } from "@reduxjs/toolkit"

export const loadingSlice = createSlice({
  name: "loading",
  initialState: false,
  reducers: {
    enableLoading: (state) => {
      state = true
      return state
    },
    disableLoading: (state) => {
      state = false
      return state
    },
  },
})

export const selectLoading = (state) => state.loading
export const { enableLoading, disableLoading } = loadingSlice.actions
export default loadingSlice.reducer
