exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activations-devices-js": () => import("./../../../src/pages/activations/devices.js" /* webpackChunkName: "component---src-pages-activations-devices-js" */),
  "component---src-pages-activations-index-js": () => import("./../../../src/pages/activations/index.js" /* webpackChunkName: "component---src-pages-activations-index-js" */),
  "component---src-pages-activations-jobs-js": () => import("./../../../src/pages/activations/jobs.js" /* webpackChunkName: "component---src-pages-activations-jobs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oidc-login-js": () => import("./../../../src/pages/oidc/login.js" /* webpackChunkName: "component---src-pages-oidc-login-js" */),
  "component---src-pages-oidc-silent-js": () => import("./../../../src/pages/oidc/silent.js" /* webpackChunkName: "component---src-pages-oidc-silent-js" */),
  "component---src-pages-smb-activations-index-js": () => import("./../../../src/pages/smb-activations/index.js" /* webpackChunkName: "component---src-pages-smb-activations-index-js" */)
}

